<template>
  <div class="homepage-hero-module">
    <custom-header></custom-header>
    <div class="login-container">
      <div class="left-show">
        <p class="show-name">快速管理生命纪念馆</p>
        <div class="show-list">
          <div class="show-item" v-for="(item, index) in list" :key="index">
            <img :src="item.icon" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="new-password"
        label-position="left"
      >
        <div class="title-container">
          <h3
            class="title"
            :class="checkedTab == 1 ? 'checked' : ''"
            @click="changeTab(1)"
          >
            账号登录
          </h3>
          <h3
            class="title"
            :class="checkedTab == 2 ? 'checked' : ''"
            @click="changeTab(2)"
          >
            验证码登录
          </h3>
        </div>
        <el-form-item prop="mobile">
          <div class="form-item">
            <div class="svg-container svg-container_login">
              <img src="@/assets/images/user.png" alt="" />
            </div>
            <el-input
              v-model="loginForm.mobile"
              name="mobile"
              type="text"
              maxlength="11"
              auto-complete="new-password"
              placeholder="请输入手机号"
              class="loginInput"
               href="#"
            />
          </div>
        </el-form-item>
        <el-form-item prop="pwd" v-if="checkedTab == 1">
          <div class="form-item">
            <span class="svg-container">
              <img src="@/assets/images/Password.png" alt="" />
            </span>
            <el-input
              :type="passwordType"
              v-model="loginForm.pwd"
              name="pwd"
              auto-complete="new-password"
              placeholder="请输入密码"
              @keyup.enter.native="handleLogin"
              class="loginInput"
               href="#"
            />
            <!-- <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span> -->
          </div>
        </el-form-item>

        <el-form-item prop="verifyCode" v-else>
          <div class="form-item">
            <span class="svg-container">
              <img src="@/assets/images/Password.png" alt="" />
            </span>
            <el-input
              v-model="loginForm.verifyCode"
              name="verifyCode"
              auto-complete="new-password"
              placeholder="请输入验证码"
              @keyup.enter.native="handleLogin"
              class="loginInput"
               href="#"
            />
            <!-- <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span> -->
            <el-button
              @click="sendCode"
              :disabled="smsFlag"
              :style="{
                fontSize: '14px',
                backgroundColor: sendColor,
                borderColor: sendColor,
                color: '#26201e',
                marginRight: '10px',
              }"
            >
              {{ sendTime }}
            </el-button>
          </div>
        </el-form-item>
        <div class="forget">
          <p><router-link :to="{ name: 'register' }">账号注册</router-link></p>
          <p>
            <router-link :to="{ name: 'findPassword' }">忘记密码</router-link>
          </p>
        </div>
        <div class="my-btn">
          <el-button
            :loading="loading"
            type="primary"
            @click.native.prevent="handleLogin"
            >登录
          </el-button>
          <img src="@/assets/images/WeChatr.png" alt="" @click="wechatLogin" />
        </div>
      </el-form>
    </div>
    <!-- <custom-footer></custom-footer> -->
  </div>
</template>

<script>
import { sendSms , wechatLogin} from "@/api/index";
export default {
  name: "login",
  components: {},
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback("请填写正确的手机号");
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      let reg = /^[^\s]{6,10}$/;
      if (this.checkedTab == 1) {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else if (!reg.test(value)) {
          callback(new Error("密码6-10位，不包含空格"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (this.checkedTab == 2) {
        if (value === "") {
          callback(new Error("请输入验证码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      sendTime: "获取验证码",
      sendColor: "#fdb64f",
      snsMsgWait: 60,
      smsFlag: false,
      checkedTab: 1,
      loginForm: {
        mobile: "",
        pwd: "",
        verifyCode: "",
      },
      loginRules: {
        mobile: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        verifyCode: [{ validator: validateCode, trigger: "blur" }],
      },
      passwordType: "password",
      loading: false,
      list: [
        { icon: require("@/assets/images/video.png"), name: "视频" },
        { icon: require("@/assets/images/frequency.png"), name: "音频" },
        { icon: require("@/assets/images/Photo.png"), name: "照片" },
        { icon: require("@/assets/images/story.png"), name: "故事" },
        { icon: require("@/assets/images/annivers.png"), name: "纪念" },
      ],
    };
  },
  methods: {
    changeTab(type) {
      this.checkedTab = type;
    },

    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid && !this.loading) {
          this.loading = true;
          if (this.checkedTab == 1) {
            this.pwdLogin();
          } else {
            this.verifyCodeLogin();
          }
        } else {
          return false;
        }
      });
    },
    pwdLogin() {
      this.$store
        .dispatch("Login", this.loginForm)
        .then(() => {
          this.$store
            .dispatch("GetUserInfo", this.loginForm)
            .then((res) => {
              console.log("===============信息",res)
              localStorage.setItem('userInfo',JSON.stringify(res.data))
              // if (this.$route.query.redirect) {
              //   this.$router.push({
              //     path: decodeURIComponent(this.$route.query.redirect),
              //   }); // 跳转到原页面
              // } else {
              this.$router.push({ name: "index" }); // 正常登录流程进入的页面
             
                // console.log(localStorage.getItem('getInfo'),localStorage.getItem('Atoken'))
              // let token= localStorage.getItem('token') 
              // window.location.href="https://www.atl-life.com/userCenter/?token=" + localStorage.getItem('Atoken')
              // window.location.href="http://192.168.31.68:9528/index?token=" + localStorage.getItem('Atoken')
              //  window.location.href="http://192.168.43.180:9527/dashboard?token=" + localStorage.getItem('Atoken')
              // window.location.href="http://127.0.0.1:5500/dist/?token=" + localStorage.getItem('Atoken')



                // window.open("http://192.168.31.18:9528/index?token=" + localStorage.getItem('Atoken'));
                window.open("https://www.atl-life.com/userCenter?token=" + localStorage.getItem('Atoken'));
                window.close();
              
              // 192.168.43.180
              // window.open("http://192.168.31.68:9527/dashboard"); 
              // }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    verifyCodeLogin() {
      let params = {
        mobilePhone: this.loginForm.mobile,
        verifyCode: this.loginForm.verifyCode,
      };
      this.$store
        .dispatch("verifyCodeLogin", params)
        .then(() => {
          this.$store
            .dispatch("GetUserInfo", params)
            .then(() => {
              // if (this.$route.query.redirect) {
              //   this.$router.push({
              //     path: decodeURIComponent(this.$route.query.redirect),
              //   }); // 跳转到原页面
              // } else {
              this.$router.push({ name: "index" }); // 正常登录流程进入的页面
              // }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取验证码
    sendCode() {
      if (!/^1\d{10}$/.test(this.loginForm.mobile)) {
        this.$message.info("请填写正确的手机号");
        return;
      }
      var params = {
        mobile: this.loginForm.mobile,
        type: 5,
      };
      sendSms(params).then(() => {
        this.$message.success("短信发送成功");
        this.countDown();
      });
    },
    countDown() {
      // 60秒后重新获取验证码
      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.sendColor = "#E0E0E0";
          this.sendTime = this.snsMsgWait + "s后可重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.smsFlag = false;
            this.sendColor = "#fdb64f";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
          }
        }.bind(this),
        1000
      );
    },
    // 微信登录
    wechatLogin() {
      let date = new Date()
      let time =date.valueOf()
      console.log(time)
      localStorage.setItem('time',time)
    
      wechatLogin().then((res) => {
        
        console.log("====",res)
        window.location.href = res.data;
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #fdb64f;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  height: 100%;
  width: 100%;
  background: url("~@/assets/images/denglu.png") no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  box-sizing: border-box;
  padding: 10%;
  .left-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .show-name {
      color: $bg;
      font-size: 50px;
      font-weight: bold;
      letter-spacing: 15px;
      text-shadow: 3px 5px 18px 14px rgba(51, 36, 22, 0.55);
      margin-bottom: 12%;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -30px;
        right: 14%;
        background: url("~@/assets/images/Beckoning.png") no-repeat;
        background-size: 100%;
        z-index: 1;
        width: 45%;
        height: 30px;
      }
    }
    .show-list {
      width: 96%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .show-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #e9e5e5;
        font-size: 18px;
        img {
          width: 70px;
          height: 70px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .login-form {
    width: 380px;
    height: 450px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    padding: 40px 40px 60px;
    box-sizing: border-box;
  }

  .form-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_login {
      font-size: 20px;
    }
  }

  .title-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 400;
      color: #888585;
      margin: 0px auto 40px auto;
      text-align: center;
      position: relative;
      cursor: pointer;
      &.checked {
        color: $bg;
      }
      &.checked::after {
        width: 40px;
        height: 3px;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translateX(-50%);
        z-index: 1;
        background: $bg;
      }
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 15px;
  a {
    font-size: 14px;
    color: #fff;
  }
}
.my-btn {
  margin: 30px auto;
  text-align: center;

  button {
    width: 100%;
    height: 50px;
    background: $bg;
    border-radius: 33px;
    color: #26201e;
    font-size: 20px;
  }
  img {
    margin-top: 30px;
    width: 45px;
    height: 45px;
    cursor:pointer;
  }
}
.homepage-hero-module {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
/deep/.loginInput input{
  cursor:pointer ;
}
</style>
